<template>
  <div id="materialSearch">
    <!-- 顶部筛选 -->
    <div class="filter">
      <div class="filterItem">
        <div style="text-align: right;margin-right:10px">规格</div>
        <Input
          placeholder="请输入规格，查询原料在库数量，多个内容请用回车键隔开"
          style="width: 600px"
          type="textarea"
          :rows="5"
          v-model="specifications"
        />
      </div>
    </div>
    <div class="button">
      <div class="buttonRow">
        <div class="left">
          <div class="item" @click="refresh">
            <i class="iconfont iconicon-shuaxin"></i>
            刷新
          </div>
          <div class="item" @click="syncTableData">
            <i class="iconfont iconicon-tongbu"></i>
            同步
          </div>
          <!-- <div class="item" @click="showTableTitleDialog">
            <i class="iconfont iconicon-chuansuokuang"></i>
            表格设置
          </div> -->
          <div class="item"></div>
          <div class="item" @click="exportTableList">
            <i class="iconfont iconicon-daochu"></i>
            导出
          </div>
          <div class="item" @click="changeSpecification">
            <i class="iconfont iconquerenshaixuan"></i>
            确认筛选
          </div>
        </div>
      </div>
    </div>
    <Table
      :columns="tableColumns"
      :data="tableData"
      border
      show-summary
      :summary-method="handleSummary"
      :loading="loading"
    ></Table>
    <Page
      class-name="page"
      :total="listData.total"
      show-elevator
      show-sizer
      show-total
      :page-size="listData.pageSize"
      :current="listData.pageNum"
      @on-page-size-change="pageSizeChange"
      @on-change="pageNumberChange"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      specifications: "",
      listData: {
        specification: [],
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      clothNumTotal: "",
      numTotal: "",
      tableColumns: [
        {
          title: "序号",
          render: (h, params) => {
            return h(
              "span",
              params.index +
                (this.listData.pageNum - 1) * this.listData.pageSize +
                1
            );
          },
          width: 65,
          align: "center"
        },
        {
          title: "规格",
          key: "specification",
          minWidth: 400
        },
        {
          title: "色号",
          key: "colorNum",
          minWidth: 100
        },
        {
          title: "颜色",
          key: "color",
          minWidth: 100
        },
        {
          title: "件数 ",
          key: "clothNum",
          minWidth: 100
        },

        {
          title: "数量(KG)",
          key: "num",
          render: (h, params) => {
            return h(
              "span",
              params.row.num.toFixed(2)
            );
          },
          minWidth: 200
        },
        {
          title: "存放位置",
          key: "location",
          minWidth: 150
        }
      ],
      tableData: []
    };
  },
  created() {
    this.getTableDataList();
  },
  methods: {
    pageSizeChange(pageSize) {
      this.listData.pageSize = pageSize;
      this.listData.pageNum = 1;
      this.getTableDataList();
    },
    pageNumberChange(pageNum) {
      this.listData.pageNum = pageNum;
      this.getTableDataList();
    },
    changeSpecification() {
      this.listData.specification = this.specifications.split("\n");
      const newSpecification = this.listData.specification.map((item) => {
        return item.replace(/(^\s*)|(\s*$)/g, "");
      });
      this.listData.specification = newSpecification.filter((item) => {
        return item !== "";
      });
      this.getTableDataList();
    },
    exportTableList() {
      let specification = "";
      if (this.listData.specification.length > 0) {
        specification = JSON.stringify(this.listData.specification);
      }
      window.location.href = `${this.baseUrl}/dtsum/zongtong/material/materialController/exportMaterialList?specification=${specification}`;
    },
    // 刷新表格
    refresh(){
      this.loading = true;
      this.getTableDataList();
    },
    // 同步表格数据
    syncTableData() {
      this.loading = true;
      this.axios({
        url: "/dtsum/zongtong/basic/CommonController/synTable",
        method: "get"
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.$Message.success("同步成功！");
            this.getTableDataList();
          } else {
            this.$Message.warning("同步失败！");
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取表格数据
    getTableDataList() {
      let specification = "";
      if (
        this.listData.specification !== "" &&
        this.listData.specification.length !== 0
      ) {
        specification = JSON.stringify(this.listData.specification);
      }
      this.axios({
        url: "/dtsum/zongtong/material/materialController/materialSearch",
        method: "get",
        params: {
          specification: specification,
          pageSize: this.listData.pageSize,
          pageNum: this.listData.pageNum
        }
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.tableData = res.data.body.materialList.list;
            this.listData.total = res.data.body.materialList.total;
            this.listData.pageSize =
              res.data.body.materialList.pageSize;
            this.listData.pageNum =
              res.data.body.materialList.pageNum;
            this.clothNumTotal = res.data.body.clothNumTotal;
            this.numTotal = res.data.body.numTotal.toFixed(2);
          }
            this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    // 计算总值
    handleSummary({ columns, data }) {
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: "累计"
          };
        } else if (key === "clothNum") {
          sums[key] = {
            key,
            value: this.clothNumTotal
          };
        } else if (key === "num") {
          sums[key] = {
            key,
            value: this.numTotal
          };
        } else {
          sums[key] = {
            key,
            value: ""
          };
        }
      });
      return sums;
    }
  }
};
</script>

<style lang="scss">
#materialSearch {
  .filter {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    font-size: $--fontsize-small;
    .filterItem {
      display: inline-flex;
      line-height: 32px;
      i {
        margin-left: 9px;
      }
    }
  }
  .button {
    font-size: $--fontsize-small;
    margin-bottom: 20px;
    .buttonRow {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
      .left {
        .item {
          display: inline-block;
          margin-right: 30px;
          color: $--color-blue-standard;
          cursor: pointer;
          &:nth-child(2) {
            margin-right: 20px;
          }
          &:nth-child(3) {
            width: 1px;
            height: 14px;
            background: #dcdada;
            margin-right: 20px;
            vertical-align: middle;
          }
        }
      }
      .right{
        .item {
          margin-left: 10px;
        }
        .filterButton{
          width:130px;
          height:32px;
          line-height:32px;
          text-align: center;
        }
      }
    }
  }
  .page {
    margin-top: 10px;
    text-align: center;
  }
}
</style>
